import React from 'react';

import DemoCTA from '../components/demoCta';
import Layout from '../components/layout';

function FeatureList(featureList) {
  return (
    <ul>
      {featureList.map((feature, i) => {
        return (
          <li className={`flex items-start ${i === 0 ? '' : 'mt-4'}`} key={i}>
            <div className="flex-shrink-0">
              <svg
                className="h-6 w-6 text-brandgreen"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-500">
              {feature}
            </p>
          </li>
        );
      })}
    </ul>
  );
}

function RegularPlan(
  { plan, tagline, featureList }: PlanSegment,
  position: 'left' | 'right'
) {
  return (
    <div
      className={`h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none ${
        position === 'left' ? 'lg:rounded-l-lg' : 'lg:rounded-r-lg'
      }`}
    >
      <div className="flex-1 flex flex-col">
        <div className="bg-white px-6 py-6">
          <div>
            <h3 className="text-center text-2xl leading-8 font-medium text-gray-900">
              {plan}
            </h3>
            {tagline && (
              <p className="text-center text-base leading-6 font-medium text-gray-500">
                {tagline}
              </p>
            )}
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-200 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
          {FeatureList(featureList)}
        </div>
      </div>
    </div>
  );
}

function HighlightedPlan({ plan, tagline, badge, featureList }: PlanSegment) {
  return (
    <div className="relative z-10 rounded-lg shadow-xl">
      <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-brandbg"></div>
      {badge && (
        <div className="absolute inset-x-0 top-0 transform translate-y-px">
          <div className="flex justify-center transform -translate-y-1/2">
            <span className="inline-flex rounded-full bg-brandbg px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
              {badge}
            </span>
          </div>
        </div>
      )}
      <div className="bg-brandblue rounded-t-lg px-6 pt-12 pb-10">
        <div>
          <h3 className="text-center text-3xl leading-9 font-semibold text-gray-100 sm:-mx-6">
            City
          </h3>
          {tagline && (
            <p className="text-center text-base leading-6 font-medium text-gray-100">
              {tagline}
            </p>
          )}
        </div>
      </div>
      <div className="border-t-2 border-gray-200 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
        {FeatureList(featureList)}
      </div>
    </div>
  );
}

interface PlanSegment {
  plan: string;
  badge?: string;
  tagline: string;
  featureList: string[];
}

const planSegments: PlanSegment[] = [
  {
    plan: 'Town',
    tagline: 'Only the essentials',
    featureList: [
      'GTFS and GTFS-RT support',
      'Basic analytics and reporting',
      'Standard branding options',
    ].sort(),
  },
  {
    plan: 'City',
    tagline: '',
    // badge: "Most popular",
    featureList: [
      'Advanced analytics and reporting',
      'Custom branding options',
      'Contractual uptime SLAs',
      'Canadian data locality',
      'Priority support',
    ].sort(),
  },
  {
    plan: 'Metropolis',
    tagline: 'Features for larger customers',
    featureList: [
      'Bring-your-own-cloud deployment options',
      'Custom development work',
      'Quarterly business reviews',
    ].sort(),
  },
].slice(0, 3);

export default function Pricing() {
  return (
    <Layout title="Pricing" mainClasses="max-w-screen-xl">
      <div className="bg-brandbg w-screen mx-auto">
        <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
          <div className="text-center">
            <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider sr-only">
              Pricing
            </h2>
            <p className="mt- text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
              The right plan for your community, no matter the size
            </p>
            <p className="mt-3 max-w-4xl mx-auto text-xl leading-7 text-gray-300 sm:mt-5 sm:text-2xl sm:leading-8">
              Our options are adaptable to your needs, and can change as your
              community grows.
            </p>
          </div>
        </div>

        <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
          <div className="relative z-0">
            <div className="absolute inset-0 h-5/6 bg-brandbg lg:h-2/3"></div>
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative lg:grid lg:grid-cols-7">
                <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                  {RegularPlan(planSegments[0], 'left')}
                </div>
                <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                  {HighlightedPlan(planSegments[1])}
                </div>
                <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                  {RegularPlan(planSegments[2], 'right')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {DemoCTA()}
    </Layout>
  );
}
